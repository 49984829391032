import DataObject from 'o365.modules.DataObject.ts';
import { createDataObject, getDataObjectById, getOrCreateDataObject } from 'o365.vue.ts';
import API from 'o365.modules.data.api.ts';

const stores = {};

export default class ReportDataObject extends DataObject {
    constructor(options) {
        super(options);
    }
}

const initReport = async function (report) {
    let reference = {};

    if (report && report["ReportID"] && report["ViewName"]) {
        let fields = report["FilterFields"]?.split(",").map(function (item) { return { name: item.trim(), fieldName: item.trim() } });
        
        fields = fields || JSON.parse(report["JsonDataColumns"])?.map(function (item) { return { name: item.trim(), fieldName: item.trim() } });
        
        let reportSetupObject = {
            id: report["ReportID"],
            viewName: report["ViewName"],
            maxRecords: -1,
            dynamicLoading: true,
            
            fields: fields || []

        };

        let viewDef = await initViewDefinition(reportSetupObject);
        
        reportSetupObject['viewDefinition'] = viewDef;
        reference = getOrCreateDataObject(reportSetupObject);
        reference.recordSource.maxRecords  = 100;
        
        return reference;
    }
}

const reportStore = {
    getReport: async function(report) {

        let reportID = report["ReportID"];
        if (!stores[reportID]) {
            stores[reportID] = await initReport(report);
        }
        return stores[reportID];
    }
}



const initViewDefinition = async function (setupObject) {
    if (!setupObject?.viewName){
        return null;
    }

    const requestUrl = '/api/data/';
    const requestData = {
        "operation": "retrieve",
        "viewName": 'sviw_Database_ColumnsDetails',                         
        "whereClause": "DBObjectID = '"+setupObject.viewName+"'",
        "fields": [
            {"name": "FieldName"},
            {"name": "DataType"},
            {"name": "Computed"},
            {"name": "MaxLength"},
            {"name": "Identity"},
            {"name": "HasDefault"},
            {"name": "Nullable"}
        ]
    };

    try {
        const response = await API.requestPost(requestUrl, requestData);

        let matchedFields = [];

        let newKeys = {
            FieldName:'fieldName', 
            HasDefault:'hasDefault',
            Identity:'identity',
            MaxLength:'maxLength',
            Nullable:'nullable',
            Computed:'computed',
            DataType:'dataType', 
        };

        setupObject.fields.forEach(function (item) {            
            let matchedField = response.find(x => x.FieldName == item.name.trim());
            
            matchedField = renameKeys(matchedField, newKeys);
            
            matchedFields.push(matchedField);
        });

        let viewDefinition = [];

        matchedFields.forEach((field, index) =>{
            Object.keys(field).forEach((fieldProperty)=>{
                viewDefinition[index] = viewDefinition[index] || {};
                viewDefinition[index][fieldProperty] = field[fieldProperty];
            });
        });

        return viewDefinition;
    } catch (error) {
        console.log(error);
    }
}

function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;

        return { [newKey]: obj[key] };
    });

    return Object.assign({}, ...keyValues);
}

export { initReport, reportStore }
